var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-main',[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$router.push({ path: '/marketing/repairPurchaseAdd' })}}},[_vm._v("+添加采购")]),_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"140px"}},[_c('el-form-item',{attrs:{"label":"商品信息："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入商品名称"},model:{value:(_vm.searchForm.title),callback:function ($$v) {_vm.$set(_vm.searchForm, "title", $$v)},expression:"searchForm.title"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"25px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.search}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.clearSearch}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.list,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"consumer_card_picture","label":"商品信息","align":"center","min-width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-image',{staticStyle:{"width":"60px","height":"60px","margin-right":"10px","flex-shrink":"0"},attrs:{"src":row.goods_image,"alt":""}}),_c('div',{staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(row.goods_title))])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"goods_price","label":"价格","align":"center","width":"130"}}),_c('el-table-column',{attrs:{"label":"第一阶起批量","align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ladder_price_info[0].start)+"~"+_vm._s(row.ladder_price_info[0].end)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"批发价格","align":"center","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.is_mixedbatchh ? '--' : row.ladder_price_info[0].price)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"第二阶起批量","align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ladder_price_info[1].start)+"~"+_vm._s(row.ladder_price_info[1].end)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"批发价格","align":"center","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.is_mixedbatchh ? '--' : row.ladder_price_info[1].price)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"第三阶起批量","align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ladder_price_info[2].start)+"~ ")]}}])}),_c('el-table-column',{attrs:{"label":"批发价格","align":"center","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.is_mixedbatchh ? '--' : row.ladder_price_info[2].price)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"150","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({ path: '/marketing/repairPurchaseEdit', query: { id: scope.row.id } })}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleDel(scope.row.id)}}},[_vm._v("删除")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total_number,"page":_vm.searchForm.page,"pageNum":_vm.searchForm.rows},on:{"updatePageNum":_vm.updateData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }